<template>
    <div>
        <h1 class="sim-h1 mb-10">
            {{ $t('pages.blog.title') }}

            <template v-if="activeCategory">
                - {{ activeCategory?.name }}
            </template>
        </h1>

        <div v-if="activeCategory?.description" class="sim-wysiwyg-text mb-10" v-html="activeCategory?.description" />

        <div class="sim-eyebrow text-basic-600 mb-4">
            {{ $t('pages.blog.category') }}
        </div>

        <div class="mb-11 flex flex-wrap gap-4">
            <BaseUiButton
                v-if="activeCategory"
                :to="getDynamicRoute('page', 'blog-list')"
                color="primary"
                class="uppercase"
                pill
            >
                {{ $t('pages.blog.all_categories') }}
            </BaseUiButton>

            <BaseUiButton
                v-else
                :to="route.path"
                color="simplo"
                class="pointer-events-none uppercase"
                pill
            >
                {{ $t('pages.blog.all_categories') }}
            </BaseUiButton>

            <BaseUiButton
                v-for="category in articleCategories"
                :key="category.id!"
                :to="category?.urls ?? ''"
                color="primary"
                class="uppercase"
                pill
            >
                {{ category.name }}
            </BaseUiButton>
        </div>

        <NoResultsComponent v-if="!articles.length"
                            image-url="/images/category.svg"
                            :title="$t('pages.blog.empty')"
                            :subtitle="$t('search.no_blogs_found')"
        />

        <section v-else class="sim-blog__list">
            <BlogArticleCard
                v-for="(article, index) in articles"
                :key="article.id!"
                :article="article"
                :type="index === 0 ? 'main' : 'normal'"
            />
        </section>
    </div>
</template>

<script lang="ts" setup>

const {
    activeCategory,
    articleCategories,
    articles,
} = defineProps<{
    activeCategory?: InstanceType<typeof ArticleCategoryModel> | null
    articleCategories: InstanceType<typeof ArticleCategoryModel>[]
    articles: InstanceType<typeof ArticleModel>[]
}>()

const { getDynamicRoute } = useDynamicRoutes()

const route = useRoute()

</script>

<style lang="scss" scoped>
.sim-blog__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    gap: 2.125rem;

    @include more-than(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include more-than(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
