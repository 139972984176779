import { mapLangIso1toIso2 } from '@composable-api-utils/data'
import type { DynamicPages } from '@core-theme/types/theme'
import { withLeadingSlash, withQuery } from 'ufo'

type DynamicRoutes = {
    'static-page': {}
    'page': {}
    'search': {
        queries: {
            search_query: string
        }
    }
}

interface DynamicRouteOptions<K extends keyof DynamicRoutes> {
    query: DynamicRoutes[K] extends { queries: any } ? Partial<DynamicRoutes[K]['queries']> : never
}

interface DynamicRoutePageOptions<K extends keyof DynamicPages> {
    query: Partial<DynamicPages[K]['queries']>
}

export function useDynamicRoutes() {
    const propertiesStore = usePropertiesStore()
    const { lang } = useProperties()

    function getDynamicRoute<K extends keyof DynamicPages>(type: 'page', page: K, options?: DynamicRoutePageOptions<K>): string | null
    function getDynamicRoute(type: 'search', options?: DynamicRouteOptions<'search'>): string | null
    function getDynamicRoute(type: 'static-page', id: number, options?: DynamicRouteOptions<'static-page'>): string | null
    function getDynamicRoute<T extends keyof DynamicRoutes, K extends keyof DynamicPages>(
        type: T | K,
        ...args:[K, DynamicRoutePageOptions<K>?] | [DynamicRouteOptions<'search'>?] | [number, DynamicRouteOptions<'static-page'>?]
    ): string | null {
        const [param1, param2] = args

        // the additional options for the route (e.g. query params, etc.)
        // TODO: switch for a union of multiple options when more overloads have options & type cast it correctly when assigning
        let options: Partial<DynamicRoutePageOptions<never> | DynamicRouteOptions<any>> = {}
        if (typeof param1 === 'object' && param1 !== null) {
            options = param1
        } else if (typeof param2 === 'object' && param2 !== null) {
            options = param2
        }

        // the additional identifier to get the specific route for a page type
        let identifier: string | number | null = null
        if (typeof param1 === 'string' || typeof param1 === 'number') {
            identifier = param1
        } else if (typeof param2 === 'string' || typeof param2 === 'number') {
            identifier = param2
        }

        const routes = propertiesStore.shopInfo?.routes || {}

        const routeKey = [type, identifier].filter(Boolean).join('.')
        const route = routes[routeKey]
        if (!route) {
            if (import.meta.dev) {
                warnLog(`[getDynamicRoute]: Route for '${routeKey}' was not found in the shop-info routes data.`, routes)
            }
            return null
        }
        const langCode = mapLangIso1toIso2(lang.value)
        if (!langCode) {
            if (import.meta.dev) {
                errorLog(`[getDynamicRoute]: No language was found when constructing route for: '${routeKey}'. Current locale: '${lang.value}', mapped to: '${langCode}'.`)
            }
            return null
        }

        const slug = route.slug?.[langCode] || null
        if (!slug) return null

        if (options.query) {
            const query = Object.entries(options.query).reduce((acc, entry) => {
                const [entryKey, entryValue] = entry
                const queryKey = route.queries?.[entryKey]?.[langCode] || null
                if (import.meta.dev && !queryKey) {
                    errorLog(`[getDynamicRoute]: Query key for '${entryKey}' was not found in the shop-info route queries data when constructing route for: '${routeKey}'.`, route)
                    return acc
                }

                if (queryKey) acc[queryKey] = entryValue

                return acc
            }, {} as Record<string, string>)

            return withLeadingSlash(withQuery(slug, query))
        }

        return withLeadingSlash(slug)
    }

    return {
        getDynamicRoute,
    }
}
