import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { ArticleCategoryModel } from '../models/article-category.model'

class ArticleCategoriesApiService<M extends ArticleCategoryModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export function useArticleCategoriesApiService() {
    return new ArticleCategoriesApiService({
        endpoint: '/article-categories',
    }, ArticleCategoryModel)
}
